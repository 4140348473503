import SaggingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/sagging/SaggingTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/Sagging/SaggingTemplate',
  component: SaggingTemplate
};

const createStory = props => () => ({
  components: { SaggingTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><sagging-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  saggingSkin: BOOLEAN.YES
});

export const unselected = createStory({
  saggingSkin: ''
});
