<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['saggingSkin'])">
      <single-answer-question
        id="sagging-skin"
        title="label.saggingSkin"
        hint="info.saggingSkin"
        :value="saggingSkin"
        :options="$options.yesNoResponseOptions"
        :image="$options.skinSaggingImage"
        image-alt="sagging skin image"
        yes-no
        dysfunction="skin-appearance"
        @input="onFieldChange('saggingSkin', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

import skinSaggingImage from '@/modules/questionnaire/assets/images/skin-appearance/sagging/sagging-skin.svg';

export default {
  name: 'SaggingTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  skinSaggingImage,
  props: {
    saggingSkin: {
      type: String,
      required: true
    }
  }
};
</script>
